const projects = [
	{
		title: 'PredictionStrike',
		image: '/predictionstrike.png',
		description:
			'A sports betting website that has transacted over $2M where we see athletes like stocks and adjust their price based on their in-game performance.',
		tech: [
			'devicon-javascript-plain colored',
			'devicon-nodejs-plain colored',
			'devicon-react-original-wordmark colored',
			'devicon-python-plain-wordmark colored'
		],
		link: 'https://predictionstrike.com'
	},
	{
		title: 'Perfected-Prototypes',
		image: '/perfected-prototypes.png',
		description:
			'An NPM package extension to the Javascript Object, Array, and String prototype chain. Extending the limited functionality to what it should have been the first time around. ',
		tech: ['devicon-javascript-plain colored'],
		link: 'https://github.com/El-Dringo-Brannde/Perfected-Prototypes'
	},
	{
		title: 'Middlemen',
		image: '/Middlemen.png',
		description:
			'A dead simple middleware solution for Azure Functions, similar to express, & hapiJS',
		tech: ['devicon-typescript-plain colored'],
		link: 'https://github.com/El-Dringo-Brannde/Middlemen'
	},
	{
		title: 'LunchBox',
		image: '/LunchBoxBanner.png',
		description:
			'A single page application that allows users to find eachother for lunch and let the be able to tag along or pick up something to eat. Meant to help interns and new hires meet people',
		tech: [
			'devicon-angularjs-plain-wordmark colored',
			'devicon-mongodb-plain colored',
			'devicon-sass-original colored',
			'devicon-docker-plain-wordmark colored',
			'devicon-nodejs-plain-wordmark colored',
			'devicon-express-original-wordmark colored'
		],
		link: 'https://github.com/El-Dringo-Brannde/LunchBox'
	},
	{
		title: 'Brewster',
		image: '/brewsterLogo.png',
		description:
			'A cross platform mobile app to keep track of what beers a user had, and keep track of their rating along with a picture. That way at the grocery store or a pub, you always get what you want. ',
		tech: [
			'devicon-typescript-plain colored',
			'devicon-angularjs-plain colored',
			'ion-ios-ionic-outline',
			'devicon-android-plain-wordmark colored',
			'devicon-nodejs-plain-wordmark colored',
			'devicon-apple-original colored',
			'devicon-mongodb-plain colored'
		],
		link: 'https://github.com/El-Dringo-Brannde/Brewster_Mobile'
	},
	{
		title: 'Trump Tracker',
		image: '/trump-icon.png',
		description:
			'A class project that has turned into a fun little side project. It focuses on pulling Donald Trumps Tweets and analyzing them by tweet times, word types, frequent hash tags and whom he has tweeted at. ',
		tech: [
			'devicon-react-original-wordmark colored',
			'devicon-nodejs-plain colored',
			'devicon-mongodb-plain-wordmark colored',
			'devicon-express-original-wordmark colored'
		],
		link: 'https://github.com/El-Dringo-Brannde/Trump-Twitter-Tracker-Web'
	},
	{
		title: 'Senior Capstone',
		image: '/CapstoneLogo.jpg',
		description:
			'Created a proof of concept and added the ability to use a computer to visualize data with anything but a mouse and keyboard. Instead using the Amazon Alexa and HTC Vive for data visualization',
		tech: [
			'devicon-amazonwebservices-original colored',
			'devicon-express-original-wordmark colored',
			'devicon-mongodb-plain colored',
			'devicon-nodejs-plain-wordmark colored'
		],
		link: 'https://github.com/El-Dringo-Brannde/Senior-Capstone'
	},
	{
		title: 'Tipster',
		image: '/TipsterBanner.png',
		description:
			'Originally a homework assignment, but I extended it to actually use at work. A PHP based web app that tracks pizza delivery tips and distances to avg my income and plan deliveries.',
		tech: [
			'devicon-mysql-plain-wordmark colored',
			'devicon-php-plain colored',
			'devicon-css3-plain-wordmark colored',
			'devicon-html5-plain-wordmark colored'
		],
		link:
			'https://github.com/El-Dringo-Brannde/CS340-Databases/tree/master/Final%20Project'
	},
	{
		title: 'Bonsai',
		image: '/BonsaiScreenshot.jpg',
		description:
			'Worked alongside Nicholas Nelson on his PhD IDE Bonsai (Now called Synectic). Tries the concept of making an IDE  a problem solving environment, rather than a text editor.',
		tech: [
			'devicon-atom-original colored',
			'devicon-windows8-original colored',
			'devicon-apple-original colored',
			'devicon-nodejs-plain colored',
			'devicon-mocha-plain colored'
		],
		link: 'https://github.com/nelsonni/bonsai'
	}
];

export default projects;
