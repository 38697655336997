const bio = [
	//background
	`
	My name is Brandon Dring, I'm a Senior Cloud Security Engineer, with the simple idea of pushing organizational security to the left and detecting it earlier in the development process.`,

	//current
	`Currently I work at Nike, in Beaverton, Oregon. 
	I work on the Cloud Security team within CIS, focusing largely on Google Cloud and making it have the same security posture as the other CSPs. I like to think of my role as an infra-dev-sec ops, since I do way more than just simply security engineering.`,

	// hobbies
	`In my free time, I try and play with technologies that aren't apart of my daily drivers at work so to speak. I have lately built the majority of the website and app predictionstrike.com, combining sports, stocks, and technology all together.`
];

export default bio;
