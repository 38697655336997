export const images = [
	`${process.env.PUBLIC_URL}/Yosemite.jpg`,
	`${process.env.PUBLIC_URL}/PacificOcean.jpg`,
	`${process.env.PUBLIC_URL}/MtHood.jpg`,
	`${process.env.PUBLIC_URL}/GrandCanyon.jpg`
];

// hackity hack hack
export const skills = [
	'Cloud Infrastructure',
	'Cloud Security Automation',
	'Mobile Apps',
	'Websites',
	'Home Automation',
	'Cloud Infrastructure',
	'Home Automation',
	'Cloud Security Automation',
	'Websites',
	'Mobile Apps',
	'Cloud Infrastructure',
	'Home Automation',
	'Cloud Security Automation',
	'Websites',
	'Mobile Apps',
	'Cloud Infrastructure',
	'Home Automation',
	'Cloud Security Automation',
	'Websites',
	'Mobile Apps'
];
