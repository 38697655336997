const jobExp = [
	{
		title: 'Senior Cloud Security Engineer',
		description: `InfraDevSecops engineer in charge of making Google Cloud a first class Cloud Service provider at Nike. Such as designing automated remediations across the entire organization. Governing, updating, and managing exceptions for organization policies across all the projects.
		\n 
		Currently working on building out of the box secure cloud infrastructure using Terraform and service catalog. Along with doing complete end to end project automation, with security guard rails built in.
		`,
		company: 'Nike Inc.',
		start: '03/01/20',
		end: 'Present'
	},
	{
		title: 'Cloud Engineer',
		description: `Currently designing a manageable way to edit & deploy network firewall rules. Using an infrastructure as code, rules are viewable to the entire organization, and automatically deployed upon changes. 
		\n
		Saving the Nike network team endless man hours processing tickets for firewall exceptions, this allows engineers to submit a pull request to make the exact change they need.`,
		company: 'Nike Inc.',
		start: '09/04/19',
		end: '03/01/20'
	},
	{
		title: 'Full Stack Engineer',
		description: `Developed an automated process of taking designer artwork and making it print ready. Meeting the exact specifications of the printer, saving about four hours of labor for each design that gets created. The project has since been picked up and being used by the Nike By You (Nike iD) team. 
		\n
		Designed & implemented a ReactJS/Mobx website to create a new classified method of making shoes. The website served as a front end to program a manufacturing machine, that used a custom file format. While at the same time abstracting all the technicalities so that more Nike engineers can use the machine.`,
		company: 'Nike Inc.',
		start: '03/04/19',
		end: '09/04/19'
	},
	{
		title: 'Automation Engineer',
		description: ` Audited the termination process of Nike Employees, automating a full days worth of work into a minute long Python script. This ensured that Nike employees that had been terminated, had their access revoked in a single day from up to a month to prevent access from any potentially disgruntled employees. 
		\n
		Automated how Corporate Information Security blacklists/whitelists IP addresses. Unifying to a single source of truth of what IP address ranges are safe or not, making it both easily accessible and maintainable.`,
		company: 'Nike Inc.',
		start: '09/04/18',
		end: '03/04/19'
	},
	{
		title: 'Software Development Intern',
		description: `
      Worked inside a data and analytics team, that primarily used Angular.JS, Node.JS, HapiJS, and MongoDB as their tech stack. Then deployment was handled by a kubernetes and docker combo to be deployed to the cloud, along with a Gulp build system to put it all together.
      \n
      Worked on multiple projects ranging from anything to a social networking app for interns and new hires find eachother during lunch. Or a homegrown skill searching website to help employees find other employees with the proper skills to contribute to a project. Even fixing bugs and adding features to a home made spending aggregator, built on top of yet another home grown ETL engine. All the way to tracking, displaying, and querying what product releases are on time or delayed within the company.
      \n
      Also led the charge on my team of bringing their tech stack from old vanilla Javascript in ES5, to the newer class based, async/await features of ES7. Standardized projects in respect to how they are beautified, started, and documented, to help ease the learning curve of people contributing to new projects they have never touched before.
      `,
		company: 'CDK Global',
		start: '06/15/17',
		end: 'Present'
	},
	{
		title: 'Undergraduate Student Research Assistant',
		description: `
      One of the main developers on a PhD students project in developing a new kind of IDE called Synectic/Bonsai which focused on problem solving and Human Computer Interactions rather than just coding. Tech stack included mostly ElectronJS, NodeJS, and jQuery.
      \n
      Responsibilties included choosing and developing a testing framework to automate correct placement of cards. Implemented a miniature terminal to run commands from inside the IDE. Along with improving efficiency, by splitting and coordinating parallel processes within the IDE. Then, added Markdown support for taking quick notes and saving the files locally.
      `,
		company: 'Oregon State University',
		start: 'October 2016',
		end: 'September 2016'
	}
];

export default jobExp;
